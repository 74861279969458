class Cnpj {
  constructor({
    cnpj = "",
    nome = ""
  }) {
    this.cnpj = cnpj
    this.nome = nome
  }
} 

export default Cnpj