import { snackbar } from "cids-cgi/lib/util";

import * as echarts from "echarts";
import * as _ from "lodash";
import dayjs from "dayjs";
import bwipjs from "bwip-js";

import rules from "@/core/util/Rules";
import Propriedade from "@/core/domain/model/propriedade";

import { cards } from "../const/cards";

export default class DashboardController {
  context = null;
  totalBalanceChart = null;
  balanceAvailableChart = null;
  dialog = null;
  dialogFiltro = false;
  dialogCodigoBarras = false;
  autoReload = false;
  contextDialog = null;
  loading = false;
  loadingApplication = false;
  interval = {};
  data = [];
  cnpj = [];
  saldoTotal = null;
  saldoDisponivel = null;
  dataInicial = dayjs().subtract(30, "day").format("DD/MM/YYYY");
  dataFinal = dayjs().add(0, "days").format("DD/MM/YYYY");
  configInterval = 180000;

  resultado = 0;
  cardSelecionado = null;
  cards = [];
  headers = [];
  items = [];
  itemSelecionado = {};

  cardsData = {
    receitas_bloqueada: [],
    receitas_liberadas: [],
    despesas_adiantamento: [],
    despesas_vencidas: [],
    despesas_a_vencer: [],
  };

  sortBy = "dt_vencimento";

  tabela = {
    title: "",
    color: "",
    icon: "",
    valueCard: "",
    value: "",
    config: new Propriedade({}),
  };

  rules = rules;
  dataTableKey = 0;

  constructor(
    context,
    getExtratoUseCase,
    refreshUseCase,
    getCnpjRaizUseCase,
    buscaTelaPadraoUseCase,
    gravaTelaPadraoUseCase
  ) {
    this.context = context;
    this.getExtratoUseCase = getExtratoUseCase;
    this.refreshUseCase = refreshUseCase;
    this.getCnpjRaizUseCase = getCnpjRaizUseCase;
    this.buscaTelaPadraoUseCase = buscaTelaPadraoUseCase;
    this.gravaTelaPadraoUseCase = gravaTelaPadraoUseCase;
  }

  async mounted() {
    try {
      this.loadingApplication = true;
      this.data = JSON.parse(localStorage.getItem("DATA"));

      this.handleAutoReload();

      this.saldoTotal = this.formataMoeda(this.data.saldo_total);
      this.saldoDisponivel = this.formataMoeda(this.data.saldo_disponivel);

      this.cards = this.getCardsConfig();
      this.getItems();

      this.createtotalBalanceChart();
      this.createBalanceAvailableChart();
    } catch (error) {
      snackbar.show({ message: error });
    } finally {
      this.loadingApplication = false;

      await this.getExtrato();
      await this.getCnpjSelecionado();
    }
  }

  async getCnpjSelecionado() {
    if (!this.data.cnpj) return;

    this.cnpj = await this.getCnpjRaizUseCase(this.data.cnpj.substr(0, 10));

    for (let i = 0; i < this.cnpj.length; i++) {
      if (this.cnpj[i].cnpj == this.data.cnpj) {
        this.cnpjSelecionado = this.cnpj[i];
        break;
      }
    }
  }

  async getExtrato() {
    if (!this.data.cnpj) return;

    await this.getExtratoUseCase(this.data.cnpj);
  }

  handleAutoReload() {
    this.autoReload = !!(localStorage.getItem("checkAutoReload") === "true");

    clearInterval(this.interval);

    if (this.autoReload) {
      this.interval = setInterval(() => {
        window.location.reload(true);
      }, this.configInterval);
    }
  }

  getCardsConfig() {
    const populateCardsValues = (card) => {
      return {
        ...card,
        valueCard: this.data[card.key][0].total,
        value: this.data[card.key][0].total,
      };
    };

    return cards.map(populateCardsValues);
  }

  getItems() {
    const normalizeDates = (array, toNormalize) => {
      return array.map((value) => {
        toNormalize.forEach((item) => {
          value[item] &&= dayjs(value[item]).format("DD/MM/YYYY");
        });

        return value;
      });
    };

    const toNormalize = ["dt_vencimento", "dt_emissao", "dt_recibo"];

    Object.keys(this.data).forEach((key) => {
      if (key in this.cardsData) {
        const docs = this.data[key][0].documentos ?? [];
        this.cardsData[key] = normalizeDates(docs, toNormalize);
      }
    });
  }

  async buscaPropriedades(card) {
    try {
      if (!this.cnpjSelecionado) {
        return new Propriedade({});
      }

      const payload = {
        tela: card,
        cod_emp: 0,
        cod_usuario: 0,
        diferenciador: String(this.cnpjSelecionado.cnpj).substring(0, 10),
      };

      const configuracoes = await this.buscaTelaPadraoUseCase(payload);

      return new Propriedade(
        JSON.parse(
          !_.isEmpty(configuracoes?.[0]?.valor)
            ? configuracoes?.[0]?.valor
            : "{}"
        )
      );
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  async salvarPropriedades(propriedades) {
    try {
      const payload = {
        tela: this.cardSelecionado,
        cod_emp: 0,
        cod_usuario: 0,
        diferenciador: String(this.cnpjSelecionado.cnpj).substring(0, 10),
        campos: "json",
        valores: JSON.stringify(propriedades),
      };

      await this.gravaTelaPadraoUseCase(payload);

      snackbar.show({
        message: "Configurações salvas com sucesso!",
        color: "primary",
        timeout: 2000,
      });
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  async buscaDadosCnpjSelecionado() {
    try {
      await this.getExtratoUseCase(this.cnpjSelecionado.cnpj);
      window.location.reload(true);
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  updateScreenDataTable() {
    this.dataTableKey += 1;
  }

  async selectCard(card) {
    this.cardSelecionado = card.key;
    this.tabela.color = card.color;
    this.tabela.title = card.title;
    this.tabela.icon = card.icon;
    this.tabela.value = card.value;
    this.tabela.config = await this.buscaPropriedades(card.key);

    this.dataInicial = dayjs().subtract(30, "day").format("DD/MM/YYYY");
    this.dataFinal = dayjs().add(0, "days").format("DD/MM/YYYY");

    const cardConfig = this.cards.find((c) => c.key === this.cardSelecionado);

    this.headers = cardConfig.table.headers;
    this.sortBy = cardConfig.table.sortBy;
    this.items = this.cardsData[this.cardSelecionado];

    this.updateScreenDataTable();
    this.dialog = true;
  }

  saveCheckedAutoReload() {
    localStorage.setItem("checkAutoReload", this.autoReload);

    if (this.autoReload) {
      clearInterval(this.interval);
      if (this.autoReload) {
        this.interval = setInterval(() => {
          window.location.reload(true);
        }, this.configInterval);
      }
    } else {
      clearInterval(this.interval);
    }
  }

  async filtrarPesquisa(form, cancelar) {
    try {
      if (!form.validate()) {
        return;
      }

      const dt_ini = dayjs(this.dataInicial, "DD/MM/YYYY");
      const dt_fim = dayjs(this.dataFinal, "DD/MM/YYYY");

      const cardData = [...this.cardsData[this.cardSelecionado]];

      this.items = cardData.filter((item) => {
        return (
          item[this.sortBy] &&
          dayjs(item[this.sortBy], "DD/MM/YYYY").isBetween(
            dt_ini,
            dt_fim,
            "dates",
            "[]"
          )
        );
      });

      this.resultado = this.items.reduce((prev, curr) => prev + curr.valor, 0);
      this.tabela.value = this.resultado;
      cancelar();
    } catch (error) {
      snackbar.show({ message: error });
    }
  }

  async createtotalBalanceChart() {
    await new Promise((resolver) => setTimeout(resolver, 500));
    var chartDom = document.getElementById("total_balance_chart");
    this.totalBalanceChart = echarts.init(chartDom);

    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      formatter: (params) => this.formataMoeda(params[0].value),
      grid: {
        top: "10px",
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
        backgroundColor: "#E91E63",
        show: true,
        color: "red",
      },
      xAxis: [
        {
          type: "category",
          data: [
            "Receitas Liberadas",
            "Receitas Bloqueadas",
            "Despesas Adiantamento",
            "Despesas Vencidas",
            "Despesas a Vencer",
          ],
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: 12,
            rotate: 25,
            color: "white",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            color: "white",
            formatter: (params) => this.formataMoeda(params),
          },
        },
      ],
      series: [
        {
          name: "Saldo R$",
          type: "bar",
          barWidth: "10%",
          data: [
            this.data.receitas_liberadas[0].total,
            this.data.receitas_bloqueada[0].total,
            this.data.despesas_adiantamento[0].total,
            this.data.despesas_vencidas[0].total,
            this.data.despesas_a_vencer[0].total,
          ],
          color: "white",
        },
      ],
    };

    option && this.totalBalanceChart.setOption(option);

    this.totalBalanceChart.resize();
  }

  async createBalanceAvailableChart() {
    await new Promise((resolver) => setTimeout(resolver, 500));
    var chartDom = document.getElementById("balance_available_chart");
    this.balanceAvailableChart = echarts.init(chartDom);

    let option = {
      color: "black",
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      formatter: (params) => this.formataMoeda(params[0].value),
      grid: {
        top: "10px",
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
        backgroundColor: "#4CAF50",
        show: true,
      },
      xAxis: [
        {
          type: "category",
          data: [
            "Receitas Liberadas",
            "Despesas Adiantamento",
            "Despesas Vencidas",
            "Despesas a Vencer",
          ],
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            interval: 0,
            fontSize: 12,
            rotate: 25,
            color: "white",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            color: "white",
            formatter: (params) => this.formataMoeda(params),
          },
        },
      ],
      series: [
        {
          name: "Saldo R$",
          type: "bar",
          barWidth: "10%",
          data: [
            this.data.receitas_liberadas[0].total,
            this.data.despesas_adiantamento[0].total,
            this.data.despesas_vencidas[0].total,
            this.data.despesas_a_vencer[0].total,
          ],
          color: "white",
        },
      ],
    };

    option && this.balanceAvailableChart.setOption(option);

    this.balanceAvailableChart.resize();
  }

  formataMoeda(valor) {
    return valor.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  async copiarLinhaDigitavel(item) {
    await navigator.clipboard.writeText(item.linha_digitavel);

    snackbar.show({
      message:
        "Linha digitável da fatura copiada para a área de transferência!",
      color: "primary",
      timeout: 1000,
    });
  }

  async abreDialogCodigoBarras(item) {
    this.itemSelecionado = { ...item };
    this.dialogCodigoBarras = true;

    await this.context.$nextTick();

    const canvas = document.createElement("canvas");

    try {
      bwipjs.toCanvas(canvas, {
        bcid: "code128",
        text: item.cod_barras,
        scale: 3,
        height: 10,
        includetext: true,
        textxalign: "center",
      });

      document.getElementById("image-bar-code").src =
        canvas.toDataURL("image/png");
    } catch (error) {
      snackbar.show({ message: error });
    }
  }
}
