import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../module/dashboard/view/dashboard.vue";
import Login from "../module/login/view/login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      const data = localStorage.getItem("DATA")
      const token = localStorage.getItem("auth");
      if (token && data) {
        next();
      } else {
        next("/login");
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { hideNavigation: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
