import { URL } from "@/constants"
import Handler from "@/core/util/Handler";

const primeiroAcessoRepository = (axios) => async (data) => {
  const dados = JSON.parse(data)
  try {
    const response = await axios.post(`${URL}/portal-associado/primeiro-acesso`, null, {
      headers: {
        authorization: `Bearer ${dados.token}`
    }
    });
    return response.data;
  } catch (error) {
    throw await new Handler().trataErro(error);
  }
};

export default primeiroAcessoRepository;
