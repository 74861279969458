import { URL } from "@/constants"
import Handler from "@/core/util/Handler";

const ePrimeiroAcessoRepository = (axios) => async (data) => {
  const dados = JSON.parse(data)
  try {
    const response = await axios.get(`${URL}/portal-associado/e-primeiro-acesso`, {
      headers: {
        authorization: `Bearer ${dados.token}`
    }
    });
    return response.data == 'SIM';
  } catch (error) {
    throw await new Handler().trataErro(error);
  }
};

export default ePrimeiroAcessoRepository;
