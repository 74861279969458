import { URL } from '@/constants'
import Handler from "@/core/util/Handler";
import Cnpj from '../../domain/model/cnpj';

const getCnpjRaizRepository = (axios) => async (data, cnpj) => {
  const dados = JSON.parse(data)
  try {
    const response = await axios.get(`${URL}/portal-associado/raiz-cnpj`, {
      params: { cnpj: cnpj },
      headers: {
        authorization: `Bearer ${dados.token}`
      }
    })
    const jwtAuthToken = response.data?.ttRaizCNPJ?.map((cnpj) => new Cnpj(cnpj)) ?? [];
    return jwtAuthToken;

  } catch (error) {
    localStorage.removeItem('auth', 'DATA')
    throw await new Handler().trataErro(error)
  }
}

export default getCnpjRaizRepository