import axios from "axios";
import sessionRepository from "../../module/login/data/repository/sessionRepository";
import refreshUseCase from "../../module/login/domain/usecase/refreshUseCase";

class Handler {
  getUrl() {
    if (localStorage.getItem("serviceURI") !== null) {
      return localStorage.getItem("serviceURI").split("|")[0];
    }
    return "";
  }

  async trataErro(error) {
    if (error.isAxiosError) {
      switch (error.response.status) {
        case 500:
          if (
            error.response.data.message == null ||
            error.response.data.message == undefined ||
            error.response.data.message == ""
          ) {
            return error.response.data.logErroApp[0].erro;
          }
          return error.response.data.message;
        case 401:
          const refreshRepositoryImpl = sessionRepository(axios);
          const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);
          try {
            await refreshUseCaseImpl();
          } catch (error) {
            window.location.href = "/login";
          }
          break;
        default:
          return error.response.data.message;
      }
    } else {
      switch (error.toString()) {
        case "TypeError: Cannot read property 'split' of null":
          window.location.href = "/";
          break;
        default:
          return error;
      }
      return error;
    }
  }
}

export default Handler;
