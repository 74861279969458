import dayjs from "dayjs";

const header_receitas_liberadas_e_bloqueadas = [
  {
    text: "Filial",
    align: "start",
    sortable: true,
    value: "cod_fil",
    divider: true,
  },
  {
    text: "Documento",
    align: "start",
    sortable: true,
    value: "documento",
    divider: true,
  },
  {
    text: "Emissão",
    align: "start",
    sortable: true,
    value: "dt_emissao",
    divider: true,
    sort: (a, b) => {
      const dataIni = dayjs(a, "DD/MM/YYYY");
      const dataFim = dayjs(b, "DD/MM/YYYY");
      return dataIni.diff(dataFim);
    },
  },
  {
    text: "Motorista",
    align: "start",
    sortable: true,
    value: "motorista",
    divider: true,
  },
  {
    text: "Empresa Subcontratada",
    align: "start",
    sortable: true,
    value: "nome_cli_subcontratacao",
    divider: true,
  },
  {
    text: "Data Subcontratação",
    align: "start",
    sortable: true,
    value: "dt_subcontratacao",
    divider: true,
  },
  {
    text: "Documento Subcontratação",
    align: "start",
    sortable: true,
    value: "num_cte_subcontratacao",
    divider: true,
    custom: true,
  },
  {
    text: "Cidade Origem",
    align: "start",
    sortable: true,
    value: "nome_cid_origem",
    divider: true,
  },
  {
    text: "Cidade Destino",
    align: "start",
    sortable: true,
    value: "nome_cid_destino",
    divider: true,
  },
  {
    text: "Valor Subcontratação",
    align: "start",
    sortable: true,
    value: "vlr_subcontratacao",
    divider: true,
  },
  {
    text: "Placa",
    align: "start",
    sortable: true,
    value: "placa",
    divider: true,
  },
  {
    text: "Produto",
    align: "start",
    sortable: true,
    value: "cod_produto_fre",
    divider: true,
  },
  {
    text: "Valor",
    align: "start",
    sortable: true,
    value: "valor",
    divider: true,
    custom: true,
  },
  {
    text: "Código de Barras",
    align: "start",
    sortable: false,
    value: "cod_barras",
    divider: true,
    custom: true,
  },
  {
    text: "Linha Digitável",
    align: "start",
    sortable: false,
    value: "linha_digitavel",
    divider: true,
    custom: true,
  },
];

const header_despesas_vencidas_e_vencer = [
  {
    text: "Filial",
    align: "start",
    sortable: true,
    value: "cod_fil",
    divider: true,
  },
  {
    text: "Documento",
    align: "start",
    sortable: true,
    value: "documento",
    divider: true,
  },
  {
    text: "Vencimento",
    align: "start",
    sortable: true,
    value: "dt_vencimento",
    divider: true,
    sort: (a, b) => {
      const dataIni = dayjs(a, "DD/MM/YYYY");
      const dataFim = dayjs(b, "DD/MM/YYYY");
      return dataIni.diff(dataFim);
    },
    //custom: true,
  },
  {
    text: "Emissão",
    align: "start",
    sortable: true,
    value: "dt_emissao",
    divider: true,
    sort: (a, b) => {
      const dataIni = dayjs(a, "DD/MM/YYYY");
      const dataFim = dayjs(b, "DD/MM/YYYY");
      return dataIni.diff(dataFim);
    },
  },
  {
    text: "Parcela",
    align: "start",
    sortable: true,
    value: "parcela",
    divider: true,
  },
  {
    text: "Placa",
    align: "start",
    sortable: true,
    value: "placa",
    divider: true,
  },
  {
    text: "Tipo",
    align: "start",
    sortable: true,
    value: "tipo",
    divider: true,
  },
  {
    text: "Observações",
    align: "start",
    sortable: true,
    value: "observacoes",
    divider: true,
  },
  {
    text: "Valor",
    align: "start",
    sortable: true,
    value: "valor",
    divider: true,
    custom: true,
  },
  {
    text: "Código de Barras",
    align: "start",
    sortable: false,
    value: "cod_barras",
    divider: true,
    custom: true,
  },
  {
    text: "Linha Digitável",
    align: "start",
    sortable: false,
    value: "linha_digitavel",
    divider: true,
    custom: true,
  },
];

const header_despesas_adiantamento = [
  {
    text: "Filial",
    align: "start",
    sortable: true,
    value: "cod_fil",
    divider: true,
  },
  {
    text: "Documento",
    align: "start",
    sortable: true,
    value: "documento",
    divider: true,
  },
  {
    text: "Data do Recibo",
    align: "start",
    sortable: true,
    value: "dt_recibo",
    divider: true,
    sort: (a, b) => {
      const dataIni = dayjs(a, "DD/MM/YYYY");
      const dataFim = dayjs(b, "DD/MM/YYYY");
      return dataIni.diff(dataFim);
    },
  },
  {
    text: "Tipo",
    align: "start",
    sortable: true,
    value: "tipo",
    divider: true,
  },
  {
    text: "Observações",
    align: "start",
    sortable: true,
    value: "observacoes",
    divider: true,
  },
  {
    text: "Valor",
    align: "start",
    sortable: true,
    value: "valor",
    divider: true,
    custom: true,
  },
  {
    text: "Código de Barras",
    align: "start",
    sortable: false,
    value: "cod_barras",
    divider: true,
    custom: true,
  },
  {
    text: "Linha Digitável",
    align: "start",
    sortable: false,
    value: "linha_digitavel",
    divider: true,
    custom: true,
  },
];

export {
  header_receitas_liberadas_e_bloqueadas,
  header_despesas_vencidas_e_vencer,
  header_despesas_adiantamento,
};
