const rules = {
  obrigatorio: (v) => !!v || "Preencha o campo!",
  dtIniMaiorFim: (v1, v2) => {
    //Data válida: "30/12/2020" deve ser string neste formato
    let erro = "Data inicial é maior que a final";

    if (v1 == undefined || typeof v1 != "string" || v1.length != 10)
      return erro;

    if (v2 == undefined || typeof v2 != "string" || v2.length != 10)
      return erro;

    if (v1.substring(2, 3) != "/" || v1.substring(5, 6) != "/") return erro;

    if (v2.substring(2, 3) != "/" || v2.substring(5, 6) != "/") return erro;

    if (v1.split("/").reverse().join("/") > v2.split("/").reverse().join("/"))
      return erro;

    return true;
  },
};

export default rules;
