import Handler from "@/core/util/Handler";

const buscaTelaPadraoRepository = (axios) => async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("auth"))?.token;

    const response = await axios.get("/api/geral/tela-padrao", {
      headers: {
        authorization: `Bearer ${token}`,
      },
      params: { filter: JSON.stringify(payload) },
    });

    return response?.data?.ttTelaPadrao ?? [];
  } catch (error) {
    throw new Handler().trataErro(error);
  }
};

export default buscaTelaPadraoRepository;
