import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import App from "./App.vue";

import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";

import dayjs from "dayjs";
import cids from "cids-cgi/lib";
import { vMaska } from "maska";

let utc = require("dayjs/plugin/utc");
let customParseFormat = require("dayjs/plugin/customParseFormat");
let timezone = require("dayjs/plugin/timezone");
let isBetween = require("dayjs/plugin/isBetween");
let advanced = require("dayjs/plugin/advancedFormat");

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(advanced);

Vue.config.productionTip = false;
Vue.prototype.dayjs = dayjs;

Vue.use(cids);
Vue.directive("maska", vMaska);

new Vue({
  router,
  vuetify,
  cids,
  render: (h) => h(App),
}).$mount("#app");
