<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <navigation-drawer v-if="!$route.meta.hideNavigation" />
    <v-main>
      <cgi-snackbar />
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from "./module/NavigationDrawer.vue";

export default {
  name: "App",
  components: {
    NavigationDrawer,
  },
};
</script>

<style>
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  margin-right: -10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(38, 50, 80, 1)),
    color-stop(0.72, rgb(69, 96, 158, 1))
  );
}
</style>
