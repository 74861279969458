import { URL } from "@/constants"
import Handler from "@/core/util/Handler";

const alterarSenhaRepository = (axios) => async (data, form) => {
  const dados = JSON.parse(data)
  try {
    const response = await axios.put(`${URL}/portal-associado/altera-senha`, form, {
      headers: {
        authorization: `Bearer ${dados.token}`
    }
    });
    return response.data == 'SIM';
  } catch (error) {
    throw await new Handler().trataErro(error);
  }
};

export default alterarSenhaRepository;
