<template>
  <v-dialog v-model="controller.dialogCodigoBarras" width="1000">
    <v-card class="d-flex align-center" height="50vh">
      <v-card-text class="d-flex align-center justify-center py-0 px-4">
        <img id="image-bar-code" width="95%" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    controller: { type: Object, required: true },
  },
};
</script>
