const getCnpjRaizUseCase = (repository) => async (cnpj) => {
  try {
    const token = localStorage.getItem("auth");
    return await repository(token, cnpj);

  } catch (error) {
    throw error
  }
} 

export default getCnpjRaizUseCase