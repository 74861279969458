import { URL } from "@/constants"

const sessionRepository = (axios) => async (data) => {
  try {
    const response = await axios.post(`${URL}/sessao`, data);
    const jwtAuthToken = response.data;
    return jwtAuthToken;
  } catch (error) {
    throw error
  }
};

export default sessionRepository;
