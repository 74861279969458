import sessionRepository from "../../data/repository/sessionRepository";
import refreshUseCase from "./refreshUseCase";
import axios from "axios";

const getExtratoUseCase = (repository) => async (cnpj) => {
  try {
    const refreshRepositoryImpl = sessionRepository(axios);
    const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);
    await refreshUseCaseImpl();

    const token = localStorage.getItem("auth");
    const data = await repository(token, cnpj);
    localStorage.setItem("EMP", data.dSet.ttGer[0].empresa);
    localStorage.setItem("FOR", data.dSet.ttGer[0].fornecedor);
    localStorage.setItem("DATA", JSON.stringify(data.dSet.ttGer[0]));
  } catch (error) {
    throw error;
  }
};

export default getExtratoUseCase;
