<template>
  <div
    class="d-flex justify-center align-center"
    style="width: 100%; height: 100%"
    :class="{
      'skewed-bg': !$vuetify.theme.isDark,
      'skewed-bg-dark': $vuetify.theme.isDark,
    }"
  >
    <v-card max-width="600" width="600" style="z-index: 5">
      <v-card-text>
        <v-form :disabled="controller.loading" ref="formLogin">
          <v-row style="padding-top: 80px">
            <v-col class="d-flex justify-center">
              <v-img
                alt="logo"
                max-width="300"
                max-height="100"
                src="logo.png"
              />
            </v-col>
          </v-row>

          <div
            class="pb-8 d-flex flex-column justify-center"
            style="padding-top: 80px"
          >
            <v-text-field
              color="#90caf9"
              class="mx-2"
              placeholder="Código de acesso"
              background-color="#e3f2fd"
              v-model="controller.form.cliente"
              prepend-inner-icon="mdi-lock-open-outline"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              outlined
              rounded
            />

            <v-text-field
              color="#90caf9"
              class="mx-2"
              placeholder="CPF/CNPJ"
              background-color="#e3f2fd"
              v-model="controller.form.usuario"
              prepend-inner-icon="mdi-account"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              data-maska="['###.###.###-##', '##.###.###/####-##']"
              v-maska
              outlined
              rounded
            />

            <v-text-field
              v-if="controller.showSenha"
              color="#90caf9"
              class="mx-2"
              placeholder="Sua Senha"
              background-color="#e3f2fd"
              v-model="controller.form.senha"
              prepend-inner-icon="mdi-lock"
              :append-icon="
                controller.visiblePassword ? 'mdi-eye-off' : 'mdi-eye'
              "
              @click:append="
                () => (controller.visiblePassword = !controller.visiblePassword)
              "
              :type="controller.visiblePassword ? 'password' : 'text'"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              v-on:keyup.enter="controller.login()"
              outlined
              rounded
            />

            <v-btn
              class="mt-14 mx-2 white--text"
              color="#2196f3"
              height="55"
              :loading="controller.loading"
              @click="controller.login()"
              rounded
              large
            >
              Entrar
            </v-btn>

            <div class="mt-8 mx-2">
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <h4 class="font-weight-light">Siga-nos nas redes sociais!</h4>
                </v-col>

                <v-col cols="12" class="d-flex justify-space-around">
                  <v-icon
                    @click="controller.toFacebook()"
                    color="#166FE5"
                    large
                  >
                    mdi-facebook
                  </v-icon>

                  <v-icon
                    @click="controller.toInstagram()"
                    color="primary"
                    large
                  >
                    mdi-instagram
                  </v-icon>

                  <v-icon
                    @click="controller.toLinkedin()"
                    color="#0A66C2"
                    large
                  >
                    mdi-linkedin
                  </v-icon>

                  <v-icon @click="controller.toTwitter()" color="#2AA9E0" large>
                    mdi-twitter
                  </v-icon>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog persistent width="500" scrollable v-model="controller.dialog">
      <v-card>
        <v-card-title class="d-flex justify-center">
          Alterar senha
        </v-card-title>

        <v-card-text class="py-2 text-center">
          <strong class="red--text">
            Uma senha foi enviada para o o e-mail {{ controller.email }}, você
            pode utilizar ou alterar para uma de sua preferência.
          </strong>
        </v-card-text>

        <v-card-text class="py-2">
          <v-text-field
            color="#90caf9"
            class="mx-2"
            outlined
            rounded
            placeholder="Senha atual"
            background-color="#e3f2fd"
            v-model="controller.formSenha.senha_antiga"
            prepend-inner-icon="mdi-lock"
            :append-icon="
              controller.visiblePassword ? 'mdi-eye-off' : 'mdi-eye'
            "
            @click:append="
              () => (controller.visiblePassword = !controller.visiblePassword)
            "
            :type="controller.visiblePassword ? 'password' : 'text'"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            v-on:keyup.enter="controller.alteraSenha()"
          />

          <v-text-field
            color="#90caf9"
            class="mx-2"
            outlined
            rounded
            placeholder="Senha nova"
            background-color="#e3f2fd"
            v-model="controller.formSenha.senha_nova"
            prepend-inner-icon="mdi-lock"
            :append-icon="
              controller.visiblePassword ? 'mdi-eye-off' : 'mdi-eye'
            "
            @click:append="
              () => (controller.visiblePassword = !controller.visiblePassword)
            "
            :type="controller.visiblePassword ? 'password' : 'text'"
            :rules="[(v) => !!v || 'Campo obrigatório']"
            v-on:keyup.enter="controller.alteraSenha()"
          />
        </v-card-text>

        <v-card-actions class="d-flex justify-space-between px-8 pb-4">
          <v-btn
            rounded
            color="red"
            outlined
            @click="controller.cancelarSenha()"
          >
            <v-icon left>mdi-close</v-icon> cancelar
          </v-btn>

          <v-btn
            class="white--text"
            rounded
            color="#2196f3"
            @click="controller.alteraSenha()"
          >
            <v-icon left>mdi-check</v-icon> confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { loginController } from "../../di/di";

export default {
  data: (context) => ({
    controller: loginController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>

<style scoped>
.skewed-bg {
  background-image: linear-gradient(to bottom, #3a4b6c, #060922);
  padding: 2em;
  position: relative;
  min-height: 300px;
  overflow: hidden;
}
.skewed-bg::after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  right: 0;
  height: 100%;
  background: #fff;
  transform: skew(0deg, -25deg);
}

.skewed-bg-dark {
  background-image: linear-gradient(to bottom, #357cb2, #71ccd9);
  padding: 2em;
  position: relative;
  min-height: 300px;
  overflow: hidden;
}
.skewed-bg-dark::after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  right: 0;
  height: 100%;
  background: #2d2d2d;
  transform: skew(0deg, -25deg);
}
</style>
