import {
  header_despesas_adiantamento,
  header_despesas_vencidas_e_vencer,
  header_receitas_liberadas_e_bloqueadas,
} from "./headers";

export const cards = [
  {
    id: 1,
    key: "receitas_liberadas",
    title: "Total Receitas Liberadas",
    color: "#FD9A13",
    icon: "mdi-chart-bar",
    table: {
      sortBy: "dt_emissao",
      headers: header_receitas_liberadas_e_bloqueadas,
    },
  },
  {
    id: 2,
    key: "receitas_bloqueada",
    title: "Total Receitas Bloqueadas",
    color: "#e91e63",
    icon: "mdi-chart-bar",
    table: {
      sortBy: "dt_emissao",
      headers: header_receitas_liberadas_e_bloqueadas,
    },
  },
  {
    id: 3,
    key: "despesas_adiantamento",
    title: "Total Despesas Adiantamento",
    color: "#4CAF50",
    icon: "mdi-currency-usd",
    table: {
      sortBy: "dt_recibo",
      headers: header_despesas_adiantamento,
    },
  },
  {
    id: 4,
    key: "despesas_vencidas",
    title: "Total Despesas Vencidas",
    color: "#00CAE3",
    icon: "mdi-cash-multiple",
    table: {
      sortBy: "dt_vencimento",
      headers: header_despesas_vencidas_e_vencer,
    },
  },
  {
    id: 5,
    key: "despesas_a_vencer",
    title: "Total Despesas a Vencer",
    color: "#9C27B0",
    icon: "mdi-account-cash",
    table: {
      sortBy: "dt_vencimento",
      headers: header_despesas_vencidas_e_vencer,
    },
  },
];
