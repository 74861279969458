import sessionRepository from "../../data/repository/sessionRepository";
import refreshUseCase from "./refreshUseCase";
import axios from "axios";

const primeiroAcessoUsecase = (repository) => async () => {
  try {
    const refreshRepositoryImpl = sessionRepository(axios);
    const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);
    await refreshUseCaseImpl();

    const token = localStorage.getItem("auth");
    return await repository(token);
  } catch (error) {
    throw error;
  }
};

export default primeiroAcessoUsecase;
