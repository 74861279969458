class Propriedade {
  constructor({
    colunas = [],
    paginacao = {},
    ordenacao = null,
    agrupamento = null,
    search = null,
  }) {
    this.colunas = colunas;
    this.paginacao = paginacao;
    this.ordenacao = ordenacao;
    this.agrupamento = agrupamento;
    this.search = search;
  }
}

export default Propriedade;
