import Handler from "@/core/util/Handler";

const gravaTelaPadraoRepository = (axios) => async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("auth"))?.token;

    const response = await axios.post("/api/geral/grava-tela-padrao", payload, {
      headers: { authorization: `Bearer ${token}` },
    });

    return response?.data?.dSet?.ttTelaPadrao ?? [];
  } catch (error) {
    throw new Handler().trataErro(error);
  }
};

export default gravaTelaPadraoRepository;
