<template>
  <div class="pt-6">
    <v-card>
      <v-app-bar
        rounded
        :color="controller.tabela.color"
        class="mx-4"
        style="position: relative; top: -20px; color: white"
      >
        <v-icon color="white">{{ controller.tabela.icon }}</v-icon>

        <h2 v-if="$vuetify.breakpoint.smAndUp" class="pl-4 font-weight-light">
          {{ controller.tabela.title }}
        </h2>

        <h4
          v-else
          class="hidden-xs-and-up pl-4 font-weight-light"
          style="line-height: 0.5cm"
        >
          {{ controller.tabela.title }}
        </h4>

        <v-spacer></v-spacer>

        <h2 v-if="$vuetify.breakpoint.smAndUp" class="pl-4 font-weight-light">
          {{ controller.formataMoeda(controller.tabela.value) }}
        </h2>

        <h4 v-else class="hidden-xs-and-up pl-4 font-weight-light">
          {{ controller.formataMoeda(controller.tabela.value) }}
        </h4>

        <v-btn class="ml-2" icon @click="controller.dialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <cgi-data-table
        :key="controller.dataTableKey"
        :linhas="controller.items"
        :colunas="controller.headers"
        :carregar="controller.loading"
        :ordenar-por="controller.sortBy"
        :propriedades="controller.tabela.config.colunas"
        :paginacao="controller.tabela.config.paginacao"
        :habilita-agrupamento="false"
        @salvar-propriedades="controller.salvarPropriedades($event)"
        altura="calc(100vh - 300px)"
        mostra-propriedades
        mostra-pesquisa
      >
        <template v-slot:valor="{ item }">
          <v-chip
            small
            :color="item.valor > 0 ? 'green' : 'red'"
            text-color="white"
          >
            {{ controller.formataMoeda(item.valor) }}
          </v-chip>
        </template>

        <template v-slot:vlr_subcontratacao="{ item }">
          <v-chip
            small
            :color="item.vlr_subcontratacao > 0 ? 'green' : 'red'"
            text-color="white"
          >
            {{ controller.formataMoeda(item.vlr_subcontratacao) }}
          </v-chip>
        </template>

        <template v-slot:num_cte_subcontratacao="{ item }">
          <span>
            {{ item.num_cte_subcontratacao }}/
            {{ item.serie_cte_subcontratacao }}
          </span>
        </template>

        <template v-slot:cod_barras="{ item }">
          <v-tooltip v-if="!!item.cod_barras" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex align-center justify-center"
              >
                <v-icon @click="controller.abreDialogCodigoBarras(item)">
                  mdi-barcode-scan
                </v-icon>
              </div>
            </template>
            <span> Visualizar o código de barras da fatura </span>
          </v-tooltip>
        </template>

        <template v-slot:linha_digitavel="{ item }">
          <v-tooltip v-if="!!item.linha_digitavel" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-on="on"
                v-bind="attrs"
                class="d-flex align-center justify-center"
              >
                <v-icon @click="controller.copiarLinhaDigitavel(item)">
                  mdi-barcode
                </v-icon>
              </div>
            </template>
            <span> Copiar linha digitável da fatura </span>
          </v-tooltip>
        </template>

        <template v-slot:botoes>
          <cgi-acoes filtro @filtro="controller.dialogFiltro = true">
            <template v-slot:formulario="{ cancelar }">
              <v-form ref="form">
                <v-card>
                  <v-card-title
                    class="white--text"
                    :style="{ background: controller.tabela.color }"
                  >
                    Filtro
                  </v-card-title>

                  <v-divider />

                  <v-card-text class="py-5">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="6">
                          <cgi-date-picker
                            nome="De"
                            v-model="controller.dataInicial"
                            :regras="[
                              controller.rules.obrigatorio,
                              controller.rules.dtIniMaiorFim(
                                controller.dataInicial,
                                controller.dataFinal
                              ),
                            ]"
                            compacto
                            clearable
                          />
                        </v-col>

                        <v-col cols="6">
                          <cgi-date-picker
                            nome="Até"
                            v-model="controller.dataFinal"
                            :regras="[controller.rules.obrigatorio]"
                            compacto
                            clearable
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions
                    class="d-flex align-center justify-space-between"
                  >
                    <cgi-btn @click="cancelar" cancelar />

                    <cgi-btn
                      @click="controller.filtrarPesquisa($refs.form, cancelar)"
                      salvar
                    />
                  </v-card-actions>
                </v-card>
              </v-form>
            </template>
          </cgi-acoes>
        </template>
      </cgi-data-table>
    </v-card>

    <dialog-codigo-barras :controller="controller" />
  </div>
</template>

<script>
import DialogCodigoBarras from "./dialog-codigo-barras.vue";

export default {
  components: { DialogCodigoBarras },
  props: {
    controller: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.controller.contextDialog = this;
  },
};
</script>

<style scoped>
:deep(td) {
  white-space: nowrap;
}
:deep(th) {
  white-space: nowrap;
}
</style>
