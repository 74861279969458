import { URL } from "@/constants"
import Handler from "@/core/util/Handler";

const getExtratoRepository = (axios) => async (data, cnpj) => {
  const dados = JSON.parse(data)
  try {
    const response = await axios.get(`${URL}/portal-associado/extrato-fornecedor`, {
     params: { cnpj: cnpj },
      headers: {
        authorization: `Bearer ${dados.token}`
      }
    });
    const jwtAuthToken = response.data;
    return jwtAuthToken;
  } catch (error) {
    localStorage.removeItem('auth', 'DATA')
    throw await new Handler().trataErro(error);
  }
};

export default getExtratoRepository;
